import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router'
import { useSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RecordsMovement from './RecordsMovement';
import { Checkbox, FormControlLabel, Grid, Button, TextField, Dialog, DialogContent, DialogTitle, DialogActions, Tooltip, CircularProgress } from '@material-ui/core';
import { globalGetService, globalPostService } from '../../../globalServices';
import { checkApiStatus,  getLocalStorageInfo, checkPermission } from '../../utils_v2';
import { assemblyTypeObj } from '../'
import ServerErrorModal from "./ServerErrorModal";
import { trackActivity } from "../../../utils/mixpanel";
import { assetTypeValues } from "../../../constants";
import { Info } from "@material-ui/icons";
import { DeletePopUp } from "../../shared_elements";
const UnlinkAssembly = ({params, unlinkAssembly, assetInfoData, toggleModalFn, getResponseBack, showOffWingStatus, recordsPermission}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [isOnWing, setOnWing] = useState(false);
    const [assembly, setAssembly] = useState({});
    const [error, setError] = useState({});
    const [pltConstants, setPltConstants] = useState([]);
    const [serverError, setErrorModal] = useState({modal:false});
    const [assemblyRecords, setAssemblyRecords] = useState({modal:false, type:'', data:null});
    const [noRecordsPermission, setNoRecordsPermission] = useState({modal:false});

    useEffect(() => {
        if(unlinkAssembly?.on_wing_aircraft?.slug){
            if(unlinkAssembly.on_wing_aircraft.slug === assetInfoData.slug){
                setOnWing(true);
            }
        }
        getPlatformConstants();
    },[]);
    const getPlatformConstants = () => {
        globalPostService('console/get-constants/',{constant_types:['off_wing_status']})
        .then(response => {
            if(checkApiStatus(response)){
                setPltConstants(response.data.data)
            }
        })
    }
    const onFieldChange = (key, value) => {
        setAssembly({...assembly, [key]:value})
    }
    const getLinkedAircraft = (flag) => {
        if(flag){
            let asset = {id:unlinkAssembly.data.id, slug:unlinkAssembly.data.slug, asset_type: assemblyTypeObj[unlinkAssembly.data.major_assembly_type]};
            setLoading(true)
            globalPostService(`console/v2/${params.type}/${params.aircraft_slug}/records-move-details/`, {asset:asset, status:'unlink'})
            .then(response => {
                setLoading(false)
                if(checkApiStatus(response)){
                    setAssemblyRecords({modal:false, data:response.data.data, type:'unlink'});
                    setAssembly({...assembly, records_movement:true});
                }
            })
        }else{
            setAssemblyRecords({modal:false, data:null, type:''});
            setAssembly({...assembly, records_movement:false})
        }
    }
    const onValidateLinkAssembly = () => {
        let validationInputs = {
            off_wing_status: showOffWingStatus ? assembly?.off_wing_status ? '':'Please select Off Wing Condition':'', 
            unlink_reason:  assembly?.unlink_reason && assembly?.unlink_reason.trim() ? '':'Please enter Reason', 
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            let asset = {id:unlinkAssembly.data.id, slug:unlinkAssembly.data.slug, asset_type: assemblyTypeObj[unlinkAssembly.data.major_assembly_type]};
            if(assembly?.records_movement &&  assemblyRecords?.data?.source?.filter(item=> item?.records_details?.is_empty_folder == false).length > 0 ){
                onUnlinkAssembly({...assembly, mock_api:true, asset:asset })
                .then(response => {
                    if(checkApiStatus(response)){
                        setAssemblyRecords({...assemblyRecords, modal:true});
                    }
                    else if(response.data.statusCode === 422) {
                        setErrorModal({modal:true, data:response})
                    }
                    else{}
                    setLoading(false);
                })
            }else{
                if(!recordsPermission?.records?.data_room.includes('MOV') && getLocalStorageInfo()?.is_records &&(noRecordsPermission?.modal == false) ){
                    setNoRecordsPermission({modal:true})
                }
                else{
                setLoading(true)
                onUnlinkAssembly({asset:asset,unlink_reason:assembly?.unlink_reason,empty_utilization_reason:unlinkAssembly?.emptyReason || null, off_wing_status:assembly?.off_wing_status||null})
                .then(response => {
                    setNoRecordsPermission({modal:false})
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        trackActivity('Item Delete', { page_title: 'Major Assembly', asset_type:assetTypeValues[assetInfoData?.asset_type.type].label, asset_name:assetInfoData?.serial_number, event_type: 'Unlink Major Assembly', event_desc: 'Unlinked Major Assembly data from Unlink Major Assembly form' });
                        toggleModalFn()
                        getResponseBack()
                    }
                    else if(response.data.statusCode === 422) {
                        setErrorModal({modal:true, data:response})
                    }
                    else{}
                    setLoading(false);
                })
            }
            }
        }else{
            setError(validationInputs);
        }
    }
    const onUnlinkAssembly = (assembly) => {
        return new Promise(
            function(resolve, reject){
                setLoading(true)
                globalPostService(`console/v2/${params.type}/${params.aircraft_slug}/unlink-major-assembly/`, assembly)
                .then(response => {
                    setLoading(false)
                    resolve(response)
                })
            }
        )
    }
    const onRecordsMovementWithAssembly = (data) => {
        setLoading(true)
        let asset = {id:unlinkAssembly.data.id, slug:unlinkAssembly.data.slug, asset_type: assemblyTypeObj[unlinkAssembly.data.major_assembly_type]};
        onUnlinkAssembly({asset:asset, records_movement:assembly?.records_movement,empty_utilization_reason:unlinkAssembly?.emptyReason||null, unlink_reason:assembly?.unlink_reason, off_wing_status:assembly?.off_wing_status||null})
        .then(response => {
            setLoading(false)
            if(checkApiStatus(response)){
                setLoading(true)
                let payload = Object.assign({},data) 
                globalPostService(`console/v2/${params.type}/${params.aircraft_slug}/records-move-data/`,payload)
                .then(response => {
                    setLoading(false)
                    if(checkApiStatus(response)){
                        window.location.reload()
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    }
                })
            }
        })
    }
    console.log('unlinkAssembly',unlinkAssembly);
    return(
        <>
            <Dialog
                open={unlinkAssembly.modal}
                className='console-management-modal'
                maxWidth="md"
            >
                <DialogTitle id="scroll-dialog-title">
                     Unlink {unlinkAssembly.data.major_assembly_name} Assembly
                </DialogTitle>
                <DialogContent dividers={true}>
                    <div style={{width:'440px'}}>
                        <Grid container spacing={1}>
                            <Grid item md={12}>
                                <p>
                                    Unlinking the {unlinkAssembly.data.major_assembly_name} major assemblies will remove it from current MSN {assetInfoData?.serial_number}
                                </p>
                            </Grid>
                            {showOffWingStatus?
                            <Grid item md={12}>
                                <Autocomplete
                                    disableClearable
                                    options={pltConstants.filter((optionItem) => optionItem.type === 'off_wing_status')}
                                    getOptionLabel={option => option.label}
                                    name="off_wing_status"
                                    value={assembly?.off_wing_status || null}
                                    onChange={(e, value) => { onFieldChange('off_wing_status', value); setError({...error,'off_wing_status':''})}}
                                    renderInput={params => <TextField required error={error?.off_wing_status} helperText={error?.off_wing_status || ''} {...params} 
                                    label={<span>Off Wing Condition
                                        <Tooltip title={
                                            <div style={{ width: '220px' }}>
                                                Assembly can be "On Wing" if the off wing condition is "Off Wing Serviceable" only.
                                            </div>}
                                            arrow>
                                            <Info style={{ fontSize: '13px', marginLeft: '3px' }} />
                                        </Tooltip>
                                    </span>}
                                    margin="normal" fullWidth InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }} variant='outlined' />}
                                />
                            </Grid>
                            : null}
                            <Grid item md={12}>
                                <TextField
                                required
                                id='unlink_reason'
                                name='unlink_reason'
                                label='Reason for Unlink'
                                value={assembly?.unlink_reason || ''}
                                fullWidth
                                error={error?.unlink_reason} 
                                inputProps={{ maxLength: 250 }}
                                helperText={error?.unlink_reason || ''}
                                onFocus={()=>setError({...error,unlink_reason:''})}
                                onChange={(e) => onFieldChange('unlink_reason', e.target.value)}
                                margin="normal"
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                variant='outlined'
                            />
                            </Grid>
                            {getLocalStorageInfo()?.is_records ?
                                <Grid item md={12}>
                                    <span>
                                        <div>
                                            <FormControlLabel
                                                value="is_titled"
                                                control={<Checkbox disabled={recordsPermission?.records?.data_room.includes('MOV') ? false:true} onChange={(e) => getLinkedAircraft(e.target.checked)} color="primary" />}
                                                label="Move with Records"
                                                checked={assembly.records_movement}
                                                labelPlacement="end"
                                            />
                                        </div>
                                        {assembly.records_movement && assemblyRecords?.data?.source?.filter(item=> item?.records_details?.is_empty_folder == false).length == 0  ?
                                            <span style={{ color: 'red', fontSize: '13px' }}> No records are found for the source MSN </span>
                                            : null
                                        }
                                    </span>
                                </Grid>
                                : null}
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                    <Button disabled={isLoading} onClick={onValidateLinkAssembly} color='primary' size='small' variant='contained'>
                        { isLoading ? <CircularProgress size={24} />:assembly?.records_movement &&  assemblyRecords?.data?.source?.filter(item=> item?.records_details?.is_empty_folder == false).length > 0 ? 'Next':'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
            { (assemblyRecords.modal && assemblyRecords?.data?.source?.length) ? 
                <RecordsMovement 
                    loading={isLoading}
                    assetInfoData={assetInfoData}
                    assemblyRecords={assemblyRecords} 
                    onRecordsMovementWithAssembly={onRecordsMovementWithAssembly}
                    toggleModalFn={() => setAssemblyRecords({...assemblyRecords, modal:false})}
                />:null
            }
               {
                serverError?.modal ?
                <ServerErrorModal
                serverError={serverError}
                toggleModalFn={()=>setErrorModal({modal:false})}
                />: null
            }
            {
                noRecordsPermission.modal ?
                    <DeletePopUp
                        modal={noRecordsPermission.modal}
                        title='Unlink Assembly'
                        confirmColor='primary'
                        confirmText='Save'
                        content={
                            <span>
                                Unlink Recquires Records Action, You do not have a Records Permission to Perform.
                                <div style={{ marginTop: '15px' }}>
                                    Are you sure, you want to Continue?
                                </div>
                            </span>}
                        toggleModalFn={() => toggleModalFn({ modal: false, data: null })}
                        deleteRecordFn={onValidateLinkAssembly}
                    /> : null
            }
        </>
    )
}
export default withRouter(UnlinkAssembly);