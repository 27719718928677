import React, { useState, useEffect, Fragment } from 'react';
import { useSnackbar } from 'notistack';
import { withRouter, browserHistory } from 'react-router';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Radio, RadioGroup, FormControl, FormLabel, FormControlLabel, Tooltip, Checkbox, Chip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { DeletePopUp, GoogleLocationField, LesseeCreate, PageLoader } from '../../shared_elements';
import { backendDateFormat, fieldDateFormat } from '../../../constants';
import { getLocalStorageInfo } from '../../../utils';
import { globalGetService, globalPostService } from '../../../globalServices';
import { checkApiStatus } from '../../utils_v2';
import { leaseStatus, subStatus } from '../';
import { trackActivity } from '../../../utils/mixpanel';
import TemplateSelection from './TemplateSelection';
import { Edit, Info } from '@material-ui/icons';
import { jetStreamInstance } from '../../../shared/components';
const AddAircraft = ({ ownersList, portfolios, lessees, operators, pltConstants, aircraftTypes, toggleModalFn, location, getResponseBack, permission }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [aircraft, setAircraft] = useState({ownership_type: 1,same_operator_lessee:false,status:null,sub_status:null});
    const [mntGrpList, setmntGrpList] = useState([]);
    const [addTemplateModal, setAddTemplateModal] = useState(false);
    const [defaultTemplate, setDefaultTemplate] = useState({});
    const [noRecordsPermission, setNoRecordsPermission] = useState({ modal: false });
    const [templateData, setTemplateData] = useState(null);
    const [templatPayload, setTemplatPayload] = useState(null);
    const [pageLoader, setPageLoader] = useState(null);

    const fetchMntGroups = (id) => {
        globalGetService(`console/aircraft-type/${id}/events/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    let mntList = [];
                    const data = response.data.data;
                    Object.keys(data).map((item) => {
                        mntList.push({ label: data[item].replace(/,/g, '/') + '', value: item })
                    });
                    setmntGrpList(mntList);
                }
            })
    }
    const onFieldChange = (keyParam, value) => {
        setAircraft({ ...aircraft, [keyParam]: value })
        if (keyParam === 'aircraft_type') {
            setAircraft({ ...aircraft, 'maintenance_event_program_group': null, 'aircraft_type': value, 'add_template':false })
            if (value) {
                fetchMntGroups(value.id)
            } else {
                setmntGrpList([])
            }
        }
        if(keyParam =='msn'){
            setDefaultTemplate({})
            setAircraft({ ...aircraft, [keyParam]: value, 'add_template':false })
        }
        if (keyParam === 'ownership_type' && jetStreamInstance){          
            setAircraft({...aircraft,[keyParam]:value,'status':null,'sub_status':null})
        }else if(keyParam === 'status' && jetStreamInstance){
            setAircraft({...aircraft,[keyParam]:value, 'sub_status':null})
        }else{
            setAircraft({...aircraft, [keyParam]:value})
        }
    }

    const onFieldChangeLessee = (e, keyParam, value) => { 
       setAircraft({ ...aircraft, [keyParam]: value })
    }

    const onRestErrorKey = (keyParam) => {
        setError({ ...error, [keyParam]: '' })
    }
    const getUuid = (data) => {
        setLoading(true)
        globalGetService(`console/aircraft/${data?.slug}/records-detail/`)
            .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    addTemplateonSubmit(templateData?.templateDetails ? templateData : defaultTemplate, data, response.data.data)
                }
            })
    }
    const addTemplateonSubmit = (templateData, assetData, recordsData) => {
        let payload = Object.assign({}, templateData)
        payload = {
            ...payload,
            asset_type: 1,
            folderDetails: payload?.checkedFolders ? [...payload?.checkedFolders] : templateData?.folderDetails,
            asset: payload?.asset ? { ...payload?.asset, id: assetData.id } : { id: assetData.id },
            recordsParentFolder: { ...recordsData?.record_folders?.technical_records, name: "technical" },
            majorAssembly: false,
            majorAssemblyAsset: null,
            majorAssemblyAssetType: null,
            initial_template:true,
            templateDetails: templateData?.templateDetails
        }
        delete payload['checkedFolders']
        delete payload['selectedTemplate']
        setPageLoader(true)
        globalPostService(`records/v2/lessor-folder-template/apply-template/${templateData.templateDetails?.id}/`, payload)
            .then(response => {
                if (checkApiStatus(response)) {
                    setPageLoader(false)
                    setTimeout(() => {
                        window.location.reload()
                    }, 200)
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                }
            });
    }

    const onAddNewAsset = (data, tempId) => {
        // Apply the validation Here
        let validationInputs = {};
        validationInputs = {
            ...validationInputs,
            msn: aircraft.msn ? '' : 'Please enter Manufacture Serial Number (MSN)',
            aircraft_type: aircraft.aircraft_type ? '' : 'Please select Aircraft Type',
            operator_id: aircraft?.status?.value == 1 && aircraft.same_operator_lessee === false ? aircraft?.operator_id ? '' : 'Please select Operator' : '',
            status: aircraft.status ? '' : 'Please select Aircraft Lease status',
            date_of_manufacture: aircraft.date_of_manufacture ? moment(aircraft.date_of_manufacture, backendDateFormat, true).isValid() ? '' : 'Please enter valid Date' : 'Please enter Date of Manufacture'
        }
        if (getLocalStorageInfo()?.defaultLessor?.id === 242) {
            validationInputs = {
                ...validationInputs,
                sub_status: aircraft.sub_status ? '' : 'Please select Sub Status'
            }
        }
        if (aircraft.ownership_type === 2 && !jetStreamInstance) {
            validationInputs = {
                ...validationInputs,
                portfolio: aircraft.owner_portfolio ? '' : 'Please select Portfolio',
            }
        }

        if (aircraft?.status?.value == 1) {
            validationInputs = {
                ...validationInputs,
                lessee_id: aircraft?.lessee_id?.value || aircraft?.lessee_id?.id ? '' : 'Please select Lessee'
            }
        }
        if (aircraft?.status?.value == 1 && aircraft?.lessee_id && aircraft.same_operator_lessee === false && aircraft?.operator_id) {
            if (aircraft.lessee_id.name == aircraft.operator_id.name) {
                validationInputs = {
                    ...validationInputs,
                    operator_id: 'Operator Should be different from Lessee'
                }
            }
        }
        if (getLocalStorageInfo()?.defaultLessor?.id === 242 && (aircraft?.status?.value == 4 || aircraft?.status?.value == 5)) {
            validationInputs = {
                ...validationInputs,
                sub_status: ''
            }
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            if (!permission?.settings?.records_template?.includes('C') && getLocalStorageInfo()?.is_records && (noRecordsPermission?.modal == false)) {
                setNoRecordsPermission({ modal: true })
            }
            else {

                setLoading(true)
                let payload = Object.assign({}, aircraft);
                payload = {
                    ...payload,
                    aircraft_type: aircraft?.aircraft_type?.id || null,
                    credit_classification: aircraft?.credit_classification?.value || null,
                    maintenance_event_program_group: aircraft?.maintenance_event_program_group?.value || null,
                    status: aircraft?.status?.value || null,
                    sub_status: aircraft?.sub_status?.value || null,
                    operator_id: aircraft?.operator_id?.id || null,
                    archive_status: aircraft?.status?.value == 5 ? true : false,
                    location: aircraft?.location ? aircraft?.location : null
                }
                if (payload.ownership_type === 2) {
                    if (payload.owner_portfolio.id) {
                        payload = {
                            ...payload,
                            owner_portfolio: payload.owner_portfolio.id
                        }
                    }
                }
                if (payload.same_operator_lessee) {
                    payload = {
                        ...payload,
                        operator_id: payload.lessee_id
                    }
                }
                let dataCopy = Object.assign({}, aircraft)
                if (data == 'notify_email') {
                    payload = {
                        ...payload,
                        notify_email: true
                    }
                }
                globalPostService(`console/add-aircraft/`, payload)
                    .then(response => {
                        setNoRecordsPermission({ modal: false })
                        if (checkApiStatus(response)) {
                            if (dataCopy?.add_template === true) {
                                getUuid(response.data.data)
                            }
                            else {
                                toggleModalFn();
                            }
                            setLoading(false)
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
                            trackActivity('Item Added', { page_title: 'Asset Listing', asset_type: 'MSN', asset_name: aircraft.msn, event_type: 'Add Aircraft', event_desc: 'Added Aircraft data from Add Aircraft form' })
                            setTimeout(() => {
                                if (payload.status == 5) {
                                    browserHistory.push('/archived-assets')
                                } else {
                                    if (location.query.apps && location.query.asset_type) {
                                        getResponseBack()
                                    } else {
                                        browserHistory.push(`/technical-specification/aircraft/${response.data.data.slug}/basic-details`)
                                    }

                                }
                            }, 800);
                        } else {
                            setLoading(false)
                            // enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
                        }
                    })
                // }
            }
        } else {
            setError(validationInputs)
        }
    }  
    const getLesseeBasedMP=(lessee)=>{
        globalGetService(`console/aircraft-type/lessee-events/${lessee?.slug}/`)
        .then(response=>{
            if(checkApiStatus(response)){
                let mntList = [];
                const data = response.data.data;
                // Object.keys(data).map((item) => {
                //     mntList.push({label: data[item].replace(/,/g, '/') + '', value: item})
                // });
                setmntGrpList(data);
            }
        })
    }
    const fetchTemplate = (flag) => {
        if (flag) {
            let payload = {}
            payload = {
                ...payload,
                asset_type: 1,
                asset: {},
                asset_model_type: aircraft.aircraft_type?.id,
                majorAssemblyAssetType: null,
                majorAssemblyAsset: {},
                position: null,
                is_major_assembly: false,
                serial_number: aircraft?.msn
            }
            setPageLoader(true)
            globalPostService(`records/v2/get-lessor-folder-template/`, payload)
                .then(response => {
                    setTemplatPayload(payload)
                    setPageLoader(false)
                    if (checkApiStatus(response)) {
                        if (response.data.data) {
                            setDefaultTemplate(response.data.data)
                        }
                    }
                    else {
                        setAddTemplateModal({ modal: true, data: {}, apiCall: 'apicall' })
                    }
                })
        }
        else {
            setDefaultTemplate({})
        }
    }
    const getTemplateDetailsFn = () => {
        setAddTemplateModal({ modal: true, data: defaultTemplate })
    }
    const saveTemplateData = (data) => {
        setTemplateData(data)
    }
    const checkTemplateData =(value) =>{
        if(!value){
            setTemplateData({}) 
        }
    }
    const checkForDefaultTemp =()=>{
        if( !defaultTemplate?.templateDetails){
            onFieldChange('add_template',false)
        }
    }
    const { jacLeaseStatus, defaultLeaseStatus, airAsiaLeaseStatus, thirdParty } = leaseStatus;  
    let jetstreamOption;
    if (aircraft?.status?.value == 1) {
        jetstreamOption = subStatus.onLease;
    } else if (aircraft?.status?.value == 2) {
        if (aircraft.ownership_type == 2) {
            jetstreamOption = subStatus.offLease.filter(item => item.value !== '25');
        } else {
            jetstreamOption = subStatus.offLease;
        }
    } else if (aircraft?.status?.value == 13) {
        jetstreamOption = subStatus.sold;
    } else if (aircraft?.status?.value == 14) {
        jetstreamOption = subStatus.partOut;
    } else if (aircraft?.status?.value == 15) {
        jetstreamOption = subStatus.installedOnly;
    } else if (aircraft?.status?.value == 12) {
        jetstreamOption = subStatus.writtenOff;
    } else {
        jetstreamOption = subStatus.defaultSubStatus;
    }    
    return (
        <Fragment>
            {pageLoader ? <PageLoader /> : null}
            <Dialog
                open={true}
                onClose={() => toggleModalFn()}
                className='console-management-modal'
                maxWidth={'md'}
            >
                <DialogTitle id="scroll-dialog-title">
                    Add Aircraft
                </DialogTitle>
                <DialogContent dividers={true}>
                    <form>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    required
                                    id="msn"
                                    label="MSN"
                                    fullWidth
                                    margin="normal"
                                    value={aircraft.msn}
                                    error={error.msn ? true : false}
                                    helperText={error.msn ? error.msn : ''}
                                    onChange={(e, value) => onFieldChange('msn', e.target.value)}
                                    onFocus={() => onRestErrorKey('msn')}
                                    inputProps={{ maxLength: 20 }}
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    options={aircraftTypes}
                                    getOptionLabel={option => option.name}
                                    id="aircraft_type"
                                    value={aircraft.aircraft_type}
                                    onChange={(e, value) => onFieldChange('aircraft_type', value)}
                                    renderInput={params => <TextField required error={error.aircraft_type ? true : false} helperText={error.aircraft_type ? error.aircraft_type : ''} onFocus={() => onRestErrorKey('aircraft_type')} {...params} label="Aircraft Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    id="current_registration_number"
                                    label="Registration"
                                    fullWidth
                                    margin="normal"
                                    value={aircraft.current_registration_number}
                                    onChange={(e, value) => onFieldChange('current_registration_number', e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 20 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        required
                                        margin="normal"
                                        id="date_of_manufacture"
                                        label="Date of Manufacture"
                                        format={fieldDateFormat}
                                        fullWidth
                                        disableFuture
                                        InputLabelProps={{ shrink: true }}
                                        minDate={moment().subtract(25, 'years')}
                                        value={aircraft.date_of_manufacture ? aircraft.date_of_manufacture : null}
                                        error={error.date_of_manufacture ? true : false}
                                        helperText={error.date_of_manufacture ? error.date_of_manufacture : ''}
                                        onChange={(data, value) => { onFieldChange('date_of_manufacture', moment(data).format(backendDateFormat)); onRestErrorKey('date_of_manufacture') }}
                                        inputVariant='outlined'
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            {/* <Grid item xs={12} md={4}>
                                <Autocomplete
                                    options={pltConstants.filter(item => item.type === 'credit_classification')}
                                    getOptionLabel={option => option.label}
                                    id="credit_classification"
                                    value={aircraft.credit_classification}
                                    onChange={(e, value) => onFieldChange('credit_classification', value)}
                                    renderInput={params => <TextField {...params} label="Credit Classification" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid> */}
                            <Grid item xs={12} md={4}>
                                <GoogleLocationField
                                    onFieldChange={onFieldChange}
                                    location={aircraft.location ? aircraft.location : ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl component="fieldset">
                                    <FormLabel className="MuiInputLabel-shrink" component="label">Ownership Status</FormLabel>
                                    <RadioGroup row aria-label="position" name="ownership_type" defaultValue="top">
                                    <FormControlLabel value={1} control={<Radio checked={aircraft.ownership_type === 1} onChange={() => onFieldChange('ownership_type', 1)} color="primary" size="small" />} label="Owned" />
                                    <FormControlLabel value={2} control={<Radio checked={aircraft.ownership_type === 2} onChange={() => onFieldChange('ownership_type', 2)} color="primary" size="small" />} label="Managed" />
                                    {jetStreamInstance?<FormControlLabel value={3} control={<Radio checked={aircraft.ownership_type === 3} onChange={() => onFieldChange('ownership_type', 3)} color="primary" size="small" />} label="Third party"/>:null}    
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {aircraft.ownership_type === 2 && !jetStreamInstance ?
                                <Grid item xs={12}>
                                    <Autocomplete
                                        options={ownersList}
                                        getOptionLabel={option => option.name}
                                        id="owner_portfolio"
                                        value={aircraft.owner_portfolio ? aircraft.owner_portfolio : null}
                                        onChange={(e, value) => onFieldChange('owner_portfolio', value)}
                                        renderInput={params => <TextField required error={error.portfolio ? true : false} helperText={error.portfolio ? error.portfolio : ''} onFocus={() => onRestErrorKey('portfolio')} {...params} label="Portfolio" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                    />
                                </Grid> : null
                            }
                            <Grid item xs={12} md={6}>
                                <Autocomplete
                                    options={getLocalStorageInfo().defaultLessor.id === 348 ? airAsiaLeaseStatus : getLocalStorageInfo().defaultLessor.id === 442 &&aircraft.ownership_type === 3 ?thirdParty :getLocalStorageInfo().defaultLessor.id === 442 && aircraft.ownership_type !== 3?jacLeaseStatus: defaultLeaseStatus}
                                    getOptionLabel={option => option.label}
                                    id="status"
                                    value={aircraft.status}
                                    onChange={(e, value) => onFieldChange('status', value)}
                                    renderInput={params => <TextField required error={error.status ? true : false} helperText={error.status ? error.status : ''} onFocus={() => onRestErrorKey('status')} {...params} label="Lease Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>
                            {aircraft?.status?.value == 4 ?
                                <Grid item xs={12} md={6}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="sold_out_date"
                                            label="Date of Sale"
                                            format={fieldDateFormat}
                                            fullWidth
                                            disableFuture
                                            InputLabelProps={{ shrink: true }}
                                            value={aircraft.sold_out_date ? aircraft.sold_out_date : null}
                                            onChange={(data, value) => onFieldChange('sold_out_date', data ? moment(data).format(backendDateFormat) : null)}
                                            minDate={(moment(aircraft.date_of_manufacture))}
                                            maxDate={moment()}
                                            inputVariant='outlined'
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid> : null
                            }
                            {pltConstants.filter(item => item.type === 'asset_sub_status').length ? aircraft?.status?.value == 5 ? null :
                                <Grid item xs={12} md={6}>
                                        <Autocomplete
                                            options = {jetStreamInstance?jetstreamOption:pltConstants.filter(item => item.type === 'asset_sub_status') }
                                            getOptionLabel={option => option.label}
                                            id="sub_status"
                                            value={aircraft.sub_status}
                                            onChange={(e, value) => onFieldChange('sub_status', value)}
                                            renderInput={params => <TextField required={getLocalStorageInfo()?.defaultLessor?.id === 242 ? true : false} error={error.sub_status ? true : false} helperText={error.sub_status ? error.sub_status : ''} onFocus={() => onRestErrorKey('sub_status')} {...params} label="Sub Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                        />
                                </Grid> : null
                            }
                              <Grid item xs={12} md={6}>
                                <Autocomplete
                                    options={mntGrpList}
                                    getOptionLabel={option => option.label}
                                    id="maintenance_event_program_group"
                                    value={aircraft?.maintenance_event_program_group || null}
                                    onChange={(e, value) => onFieldChange('maintenance_event_program_group', value)}
                                    renderInput={params => <TextField {...params} label="Maintenance Program" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                    disabled={mntGrpList.length ? false : true}
                                />
                            </Grid>
                            {aircraft?.status?.value == 1 ?
                                <>
                                    <Grid item xs={6}>
                                        <LesseeCreate
                                            options={lessees}
                                            required={aircraft?.status?.value == 1 ? true : false}
                                            paramsKey='lessee_id'
                                            optionKey='name'
                                            label='Lessee'
                                            value={aircraft.lessee_id ? aircraft.lessee_id : null}
                                            onFieldChange={(e, paramsKey, newValue) => onFieldChangeLessee(e, 'lessee_id', newValue)}
                                            error={error.lessee_id}
                                            resetErrorKey={() => onRestErrorKey('lessee_id')}
                                            disabled={aircraft?.status?.value != 1 ? true : false}
                                        />
                                        {/* <Autocomplete
                                    options={lessees}
                                    getOptionLabel={option => option.name}
                                    id="lessee_id"
                                    value={aircraft.lessee_id}
                                    onChange={(e, value) => onFieldChange('lessee_id', value)}
                                    renderInput={params => <TextField error={error.lessee_id ? true : false} required={ aircraft?.status?.value == 1 ? true : false} helperText={error.lessee_id ? error.lessee_id : ''} onFocus={() => onRestErrorKey('lessee_id')} {...params} label="Lessee" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                    disabled={aircraft?.status?.value != 1 ? true : false}
                                /> */}
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <FormControl component="fieldset">
                                            <FormLabel className="MuiInputLabel-shrink" component="label">Operator is same as Lessee ?</FormLabel>
                                            <RadioGroup row aria-label="position" name="same_operator_lessee" defaultValue={1}>
                                                <FormControlLabel value={true} control={<Radio onChange={() => onFieldChange('same_operator_lessee', true)} checked={aircraft.same_operator_lessee === true} color="primary" size="small" />} label="Yes" />
                                                <FormControlLabel value={false} control={<Radio onChange={() => onFieldChange('same_operator_lessee', false)} checked={aircraft.same_operator_lessee === false} color="primary" size="small" />} label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    {aircraft.same_operator_lessee === false ?
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                options={operators}
                                                getOptionLabel={option => option.name}
                                                id="operator_id"
                                                value={aircraft.operator_id}
                                                // error={error.operator_id ? true : false}
                                                // helperText={error.operator_id ? error.operator_id : ''}
                                                onChange={(e, value) => { onFieldChange('operator_id', value); onRestErrorKey('operator_id') }}
                                                renderInput={params => <TextField onFocus={() => onRestErrorKey('operator_id')} required={aircraft.same_operator_lessee === false && aircraft?.status?.value == 1} error={error.operator_id} helperText={aircraft.same_operator_lessee === false ? error.operator_id : ''} {...params} label="Operator" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                            />
                                        </Grid> : null
                                    }
                                </> : null
                            }
                            {
                                getLocalStorageInfo()?.is_records ?
                                    <Grid item md={12}>
                                        <FormControlLabel control={<Checkbox disabled={aircraft.aircraft_type && aircraft?.msn && permission?.settings?.records_template?.includes('C') ? false : true} size="small" color='primary' checked={aircraft?.add_template ? true : false} onChange={(e) => { onFieldChange('add_template', e.target.checked); fetchTemplate(e.target.checked); checkTemplateData(e.target.value)}} name="add_template" />} label={<p style={{ fontSize: '13px' }}>
                                            <span>Add Dataroom Template</span>
                                            <Tooltip title='Please click on “Add Dataroom Template” to choose a template and apply it to this asset’s Dataroom.' arrow>
                                                <Info style={{ fontSize: '13px', marginLeft: '3px' }} />
                                            </Tooltip>
                                        </p>} />
                                        {
                                            defaultTemplate?.templateDetails && aircraft?.add_template ?
                                                <Chip onDelete={getTemplateDetailsFn} deleteIcon={<Edit/>} variant="outlined" color="primary" label={defaultTemplate?.templateDetails?.name} size='small' onClick={() => getTemplateDetailsFn()} /> : null
                                        }
                                        <br/>
                                        <span className='notes-yellow'>
                                            {
                                                permission?.settings?.records_template?.includes('C') ?
                                                'Add MSN and Aircraft Type to enable Dataroom Template': 
                                                'You do not have the necessary permissions to enable the Add Dataroom Template.'
                                            }
                                        </span>
                                    </Grid>
                                    : null}
                        </Grid>

                    </form>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => { toggleModalFn(); trackActivity('Item Clicked', { page_title: 'Asset List', item_type: 'Cancel', event_desc: 'Clicked on Cancel Button form Add Aircraft form' }) }}>Cancel</Button>
                    <Button color="primary" variant="contained" onClick={() => { onAddNewAsset(); trackActivity('Item Clicked', { page_title: 'Asset List', item_type: 'Save ', event_desc: 'Clicked on Save Button form Add Aircraft form' }) }}>
                        {isLoading ? <CircularProgress color="#ffffff" size={24} /> : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
            {
                addTemplateModal?.modal ?
                    <TemplateSelection
                        setDefaultTemplate={setDefaultTemplate}
                        templatPayload={templatPayload}
                        addTemplateModal={addTemplateModal}
                        templateData={templateData}
                        assetData={{ ...aircraft, asset_type: 1 }}
                        templateInitialData={addTemplateModal?.data}
                        open={addTemplateModal?.modal}
                        onSubmitData={(data, tempId) => { saveTemplateData(data); setAddTemplateModal(false) }}
                        toggleModalFn={() => { setAddTemplateModal(false); checkForDefaultTemp()}}
                    />
                    : null
            }
            {
                noRecordsPermission.modal ?
                    <DeletePopUp
                        modal={noRecordsPermission.modal}
                        title='Add Aircraft'
                        confirmColor='primary'
                        confirmText='Save'
                        content={
                            <span>
                                Asset Creation Recquires Records Action, You do not have a Records Application Permission to Perform.
                                <div style={{ marginTop: '15px' }}>
                                    Are you sure, you want to Continue?
                                </div>
                            </span>}
                        toggleModalFn={() => toggleModalFn({ modal: false, data: null })}
                        deleteRecordFn={onAddNewAsset('notify_email')}
                    /> : null
            }
        </Fragment>
    )
}
export default withRouter(AddAircraft);
