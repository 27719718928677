import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Paper, Button, Dialog, DialogContent, DialogTitle, DialogActions, Grid, TextField, Tabs, Tab, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ConsoleMatrix from './ConsoleMatrix'
import LeaseMatrix from './LeaseMatrix'
import MaintenanceMatrix from './MaintenanceMatrix'
import RecordsMatrix from './RecordsMatrix'
import { globalGetService, globalPostService, globalPutService } from '../../../globalServices';
import { checkApiStatus } from '../../utils_v2';
import { NaturePeopleOutlined } from '@material-ui/icons';
const AddEditRole = ({addEditRole, roleUsers, getResponseBack, toggleModalFn}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [error, setError] = useState({});
    const [applications, setApplications] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [roleCrud, setRoleCrud] = useState(addEditRole)
    useEffect(() => {
        getApplications();
        getRoleProfile();
    },[]);
    const checkForKey = (key, arr) => {
        let permissionItem = null;
        arr.map((item) => {
            if (item.hasOwnProperty(key.toLowerCase())) {
                permissionItem = item;
            }
        })
        return permissionItem;
    }
    const getRoleProfile = () => {
        globalGetService(`console/group_profile/`,{all:true})
        .then(response => {
            if(checkApiStatus(response)){
                setProfiles(response.data.data)
            }
        });
    }
    const getApplications = () => {
        globalGetService(`console/application_dropdown/`)
        .then(response => {
            if(checkApiStatus(response)){
                setApplications(response.data.data)
            }else{

            }
        });
    }
    const getPermissionMatrix = (application) => {
        if(application){
            globalGetService(`console/role/${application.id}/`)
            .then(response => {
                if(checkApiStatus(response)){
                    setRoleCrud({...roleCrud, app:application, item: {...roleCrud.item, application:response.data.data.application, header: response.data.data.header}})
                }else{

                }
            });
        }else{
            setRoleCrud({...roleCrud, app:null, item:{...roleCrud.item, application:[], header:[]}});
        }
    }
    const onChangeField = (key, value) => {
        setRoleCrud({...roleCrud, item: {...roleCrud.item, [key]:value}});
    }
    const onChangeSelfPermission = (flag, appIndex, featureIndex, key, readKey) => {
        let permission = roleCrud.item.application[appIndex]?.feature[featureIndex].permission;
        if(key.toLowerCase() === 'read'){
            if(flag){
                permission = permission.map((item) => {
                    if(item.hasOwnProperty(key.toLowerCase())){
                        return {...item, value:flag}
                    }else{
                        if(item.has_option){
                            if(item.value === 0){
                                return item
                            }else{
                                return {...item, value:item.value === 2 && flag === 1 ? flag:item.value}
                            }
                        }else{
                            return item
                        }
                    }
                });
            }else{
                permission = permission.map((item) => {
                    const {id, disable, has_option, options, value, ...rest} = item;
                    if(has_option){
                        return { ...item ,value:0};
                    }else{
                        let key = Object.keys(rest)[0];
                        return { ...item ,[key]:false};
                    }
                });
            }
        }else{
            if(flag){
                permission = permission.map((item) => item.hasOwnProperty(key.toLowerCase()) ? {...item, value:flag}:item);
                if(readKey.has_option){
                    let readKeyValue = readKey.value === 0 || flag === 2 ? flag:readKey.value;
                    permission = permission.map((item) => item.hasOwnProperty('read') ? {...item, value:readKeyValue}:item);
                }else{
                    permission = permission.map((item) => item.hasOwnProperty('read') ? {...item, 'read':true}:item);
                }
            }else{
                permission = permission.map((item) => item.hasOwnProperty(key.toLowerCase()) ? {...item, value:flag}:item);
            }
        }
        setRoleCrud({...roleCrud, item:{
            ...roleCrud.item,
            application:roleCrud.item.application.map((ap,apIndex) =>
                apIndex !== appIndex ? ap: {...ap, feature: ap.feature.map((ft, ftIndex) =>
                    ftIndex !== featureIndex ? ft: {...ft, permission: permission}
                )}
            )
        }})
    }
    const onChangePermission = (flag, appIndex, featureIndex, key, readKey) => {
        let permission = roleCrud.item.application[appIndex]?.feature[featureIndex].permission;
        if(key.toLowerCase() === 'read'){
            if(flag){
                permission = permission.map((item) => item.hasOwnProperty(key.toLowerCase()) ? {...item, [key.toLowerCase()]:flag}:item);
            }else{
                permission = permission.map((item) => {
                    const {id, disable, has_option, options, value, ...rest} = item;
                    if(has_option){
                        return { ...item ,value:0};
                    }else{
                        let key = Object.keys(rest)[0];
                        return { ...item ,[key]:false};
                    }
                });
            }
        }else{
            if(readKey){
                if(flag){
                    permission = permission.map((item) => item.hasOwnProperty(key.toLowerCase()) ? {...item, [key.toLowerCase()]:flag}:item);
                    if(readKey.has_option && readKey.value === 0){
                        permission = permission.map((item) => item.hasOwnProperty('read') ? {...item, value:1}:item);
                    }else{
                        permission = permission.map((item) => item.hasOwnProperty('read') ? {...item, ['read']:true}:item);
                    }
                }else{
                    permission = permission.map((item) => item.hasOwnProperty(key.toLowerCase()) ? {...item, [key.toLowerCase()]:flag}:item);
                }
            }else{
                permission = permission.map((item) => item.hasOwnProperty(key.toLowerCase()) ? {...item, [key.toLowerCase()]:flag}:item);
            }
        }
        setRoleCrud({...roleCrud, item:{
            ...roleCrud.item,
            application:roleCrud.item.application.map((ap,apIndex) =>
                apIndex !== appIndex ? ap: {...ap, feature: ap.feature.map((ft, ftIndex) =>
                    ftIndex !== featureIndex ? ft: {...ft, permission: permission}
                )}
            )
        }})
    }
    const onAddEditRole = () => {
        let validationInputs = {
            app:roleCrud.app ? '':'Please select Application',
            name:roleCrud?.item?.name?.trim().length ? '':'Please enter Role Name',
            group_profile_order:roleCrud?.item?.group_profile_order ? '':'Please select Profile Type'
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            if(roleCrud?.app?.id === 8){
                let projectAccess = roleCrud.item.application.find(appItem => appItem.name === 'Project Management');
                let projectFeature = projectAccess.feature.find((featureItem) => featureItem.name === 'Projects');
                let workOrderFeature = projectAccess.feature.find((featureItem) => featureItem.name === 'WorkOrder (Add Asset)');
                let formsFeature = projectAccess.feature.find((featureItem) => featureItem.name === 'Form');
                let projectReadKy = checkForKey('Read', projectFeature.permission);
                let workOrderReadKy = checkForKey('Read', workOrderFeature.permission);
                let formsReadKy = checkForKey('Read', formsFeature.permission);
                if(projectReadKy.value === 0){
                    enqueueSnackbar('Projects Read Permission is Mandatory', { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    return
                }
                if(formsReadKy.value !== 0 && workOrderReadKy.value === 0){
                    enqueueSnackbar('To access Forms, please add WorkOrder Read Permission', { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    return
                }
                let taskAccess = roleCrud.item.application.find(appItem => appItem.name === 'Task Management');
                let tasksKey = checkForKey('Read', taskAccess.feature.find((featureItem) => featureItem.name === 'Tasks').permission);
                let taskComments = checkForKey('Read', taskAccess.feature.find((featureItem) => featureItem.name === 'Task Comment').permission); ;
                let taskDocuments = checkForKey('Read', taskAccess.feature.find((featureItem) => featureItem.name === 'Task Document').permission);
                if((taskComments.read === true || taskDocuments.read === true) && tasksKey.value === 0){
                    enqueueSnackbar('Task Read Permission is required', { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    return
                }
                let issuesKey = checkForKey('Read', taskAccess.feature.find((featureItem) => featureItem.name === 'Issues').permission);
                let issueComments = checkForKey('Read', taskAccess.feature.find((featureItem) => featureItem.name === 'Issue Comment').permission);
                let issueDocuments = checkForKey('Read', taskAccess.feature.find((featureItem) => featureItem.name === 'Issue Document').permission);
                if((issueComments.read === true || issueDocuments.read === true) && issuesKey.value === 0){
                    enqueueSnackbar('Issue Read Permission is required', { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    return
                }
            }
            if(roleCrud.item.id){
                globalPutService(`console/role/${roleCrud.app.id}/${roleCrud.item.id}/`, roleCrud.item)
                .then(response => {
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                        getResponseBack();toggleModalFn();
                    }else{
                        // enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }
                })
            }else{
                globalPostService(`console/role/${roleCrud.app.id}/`,roleCrud.item)
                .then(response => {
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                        getResponseBack();toggleModalFn();
                    }else{
                        // enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }
                })
            }
        }else{
            setError(validationInputs);
        }
    }
    return(
        <Dialog
            open={addEditRole.modal}
            onClose={toggleModalFn}
            className='console-management-modal add-edit-role-modal'
            maxWidth="xl"
        >
            <DialogTitle id="scroll-dialog-title">
            {roleCrud?.item?.id ? roleCrud?.mode === 'view' ?'View ':'Edit ':'Add '} Role
            </DialogTitle>
            <DialogContent dividers={true}>
                <div style={{width:'920px'}}>
                    <Grid container spacing={1}>
                        <Grid item md={6}>
                            <Autocomplete
                                disabled={roleCrud?.item?.id ?true:false}
                                options = {applications}
                                getOptionLabel={option => option.name}
                                id="application"
                                value={roleCrud.app ?roleCrud.app:null}
                                onChange={(e, value) => {getPermissionMatrix(value);setError({...error, 'app':''})}}
                                renderInput={params => <TextField required error={error?.app} helperText={error?.app||''} {...params} label="Select Application" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                            />
                        </Grid>
                        <Grid item md={6}></Grid>

                        <Grid item md={6}>
                            <TextField
                                disabled={addEditRole.mode === 'view'}
                                required
                                id='title'
                                label='Role Name'
                                value={roleCrud?.item?.name||''}
                                fullWidth
                                margin="normal"
                                error={error?.name}
                                helperText={error?.name||''}
                                onChange={(e) => onChangeField('name', e.target.value)}
                                InputLabelProps={{shrink: true}}
                                onFocus={() => setError({...error, 'name':''})}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={6}>
                            <Autocomplete
                                disabled={addEditRole.mode === 'view'}
                                options = {profiles}
                                getOptionLabel={option => option.name}
                                id="group_profile_order"
                                value={roleCrud?.item?.group_profile_order||null}
                                onChange={(e, value) => onChangeField('group_profile_order', value)}
                                renderInput={params => <TextField required onFocus={() => setError({...error, group_profile_order:''})} error={error?.group_profile_order} helperText={error?.group_profile_order||''} {...params} label="Select Profile Type" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                disabled={addEditRole.mode === 'view'}
                                id='description'
                                label='Role Description'
                                value={roleCrud?.item?.description||''}
                                fullWidth
                                margin="normal"
                                onChange={(e) => onChangeField('description', e.target.value)}
                                InputLabelProps={{shrink: true}}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={12}>
                            { addEditRole.mode === 'view' && roleUsers.length ?
                                <Paper style={{marginBottom:'10px'}}>
                                    <Tabs indicatorColor='primary' value={tabIndex} onChange={(e, newValue) => setTabIndex(newValue)}>
                                        <Tab label="Permission"/>
                                        <Tab label="User(s)"/>
                                    </Tabs>
                                </Paper>:null
                            }

                            { tabIndex === 0 ?
                                <div>
                                    {roleCrud?.item?.application?.length ?
                                        <ConsoleMatrix
                                            roleCrud={roleCrud}
                                            onChangePermission={onChangePermission}
                                            onChangeSelfPermission={onChangeSelfPermission}
                                            disabled={addEditRole.mode === 'view' ?true:false}
                                        />:null
                                    }
                                </div>:null
                            }
                            { tabIndex === 1 ?
                                <div>
                                    <Table className='mui-table-format'>
                                        <TableHead>
                                            <TableRow>
                                                {['Name', 'Designation'].map((userLabel, index) => <TableCell key={index}>{userLabel}</TableCell>)}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            { roleUsers.map((user, index) =>
                                                <TableRow key={index}>
                                                    <TableCell>{user.name}</TableCell>
                                                    <TableCell>{user?.designation || '--'}</TableCell>
                                                </TableRow>
                                            )}

                                        </TableBody>
                                    </Table>
                                </div>:null
                            }
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                {addEditRole.mode !== 'view' ?
                    <Button onClick={onAddEditRole} color='primary' size='small' variant='contained'>Save</Button>:null
                }
            </DialogActions>
        </Dialog>
    )
}
export default AddEditRole;
