import React from 'react';
import { Chip, TableCell, TableRow, IconButton, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { checkPermission } from '../../utils_v2';
const Role = ({item, onDelete, onEdit, onView}) => {
    return(
        <TableRow>
            <TableCell style={{width:'320px'}}>
                {item?.name||''}<br/>
                <Chip label={item.custom_group ? 'Custom':'Default'} size='small' />
            </TableCell>
            <TableCell>{item?.group_profile_order?.name||''}</TableCell>
            <TableCell>{item?.application?.name||''}</TableCell>
            <TableCell>{item.users.length}</TableCell>
            <TableCell align='right' style={{cursor:'pointer'}}>
                <Tooltip title='View' arrow><VisibilityIcon onClick={onView} color='primary' fontSize='small' /></Tooltip>
                { item.custom_group ?
                    <>
                        { checkPermission('settings','custom_roles','U') ?
                            <Tooltip title='Edit' arrow>
                                <EditIcon onClick={onEdit} color='primary' fontSize='small' />
                            </Tooltip>
                            :null
                        }
                        { checkPermission('settings','custom_roles','D') ?
                            <Tooltip title='Delete' arrow>
                                <DeleteOutlineIcon onClick={onDelete} color='secondary' fontSize='small' />
                            </Tooltip>
                            :null
                        }
                    </>:null
                }
            </TableCell>
        </TableRow>
    )
}
export default Role;