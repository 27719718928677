import React from 'react'
import { withRouter, Link } from 'react-router';
import { checkPermission, getLocalStorageInfo } from '../../utils_v2';
import { jetStreamInstance } from '../../../shared/components';
function ConfigSideNav({location}) {
    return (
        <div className='tech-specs-nav'>
            {
                checkPermission('console', 'lessor_configuration', 'R') ?
                    <div className='tech-nav-link'>
                        <Link activeClassName='active' to={`/configure`}>{jetStreamInstance?"Lessor/Holdco":"Lessor"} Configuration</Link>
                    </div>
                    : null}
            {
                checkPermission('console', 'introduction', 'R') ?
                    <div className='tech-nav-link'>
                        <Link activeClassName='active' to={`/configure/introduction`}>Introduction</Link>
                    </div>
                    : null}
            {
                checkPermission('console', 'owner_details', 'R') ?
                    <div className='tech-nav-link'>
                        <Link activeClassName='active' to={`/configure/owner-lessor`}>{jetStreamInstance?"Owner/SPV":"Owner"} & {jetStreamInstance?"Lessor/Holdco":"Lessor"}</Link>
                    </div> : null}
            {
                checkPermission('console', 'banking_details', 'R') ?
                    <div className='tech-nav-link'>
                        <Link activeClassName='active' to={`/configure/banking-details`}>Banking Details</Link>
                    </div> : null}
            {/* {
                checkPermission('console', 'add_lessee', 'R') ? */}
                    <div className='tech-nav-link'>
                        <Link className={location?.pathname?.includes('/configure/lessee')? 'active':'' } to={`/configure/lessee`}>Lessee Configuration</Link>
                    </div> 
                    {/* : null} */}
            {
                checkPermission('console', '3rd_party_integration', 'R') ?
                    <div className='tech-nav-link'>
                        <Link activeClassName='active' to={`/configure/third-party-integration`}>Third Party Integration</Link>
                    </div> : null}
        </div>
    )
}
export default withRouter(ConfigSideNav)